import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useLocale } from "./common/hooks"


function SEO({ description = "", title = "", keywords = "" }) {
  const locale = useLocale()

  const seoPostfix = "Dasha.AI";

  const image = "/thumbnail.png"

  const metaDescription = description;
  const metaTitle = title;
  const metaKeywords = keywords;

  const isRus = locale.includes("ru")

  return (
    <Helmet
      htmlAttributes={{
        lang: locale,
      }}
      title={metaTitle}
      titleTemplate={metaTitle ? `${metaTitle} | ${seoPostfix}` : seoPostfix}
      meta={[
        {
          name: "keywords",
          content: metaKeywords,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          property: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          name: `image`,
          content: image,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },

        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          property: `og:image:width`,
          content: `512`,
        },
        {
          property: `og:image:height`,
          content: `512`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: "dasha.ai",
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
      ]}
    >
      <link
        rel="alternate"
        type="application/rss+xml"
        title="RSS Dasha Blog"
        href={`/${locale.toLowerCase()}/feed.xml`}
      />

      <script src="https://www.googleoptimize.com/optimize.js?id=OPT-NV6S949"></script>
      
      {isRus ? (
        <script key="segment-ru" defer scr="/segmentRu.js"></script>
      ) : (
        <script key="segment" defer src="/segmentEn.js"></script>
      )}

      <script>
        {`
              document.addEventListener('DOMContentLoaded', () => {
                /** init gtm after 3500 seconds - this could be adjusted */
                setTimeout(initGTM, 3500);
              });
              document.addEventListener('scroll', initGTMOnEvent);
              document.addEventListener('mousemove', initGTMOnEvent);
              document.addEventListener('touchstart', initGTMOnEvent);
              
              function initGTMOnEvent (event) {
                initGTM();
                event.currentTarget.removeEventListener(event.type, initGTMOnEvent); // remove the event listener that got triggered
              }
              
              function initGTM () {
                if (window.gtmDidInit) {
                  return false;
                }
                window.gtmDidInit = true; // flag to ensure script does not get added to DOM more than once.
                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.async = true;
                script.onload = () => { dataLayer.push({ event: 'gtm.js', 'gtm.start': (new Date()).getTime(), 'gtm.uniqueEventId': 0 }); } // this part ensures PageViews is always tracked
                script.src = 'https://www.googletagmanager.com/gtm.js?id=${
                  isRus ? "G-MZ67EJSKT5" : "GTM-N7TF5X7"
                }';
              
                document.head.appendChild(script);
              }
            `}
      </script>
    </Helmet>
  )
}

export const query = {}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  image: null,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  image: PropTypes.string,
}

export default SEO
