import styled from "styled-components"
import { arrows, show, hide } from "./helpers"

const speed = "0.1s"
const attrs = ({ top, left, order }) => ({
  style: {
    top: `${top}px`,
    left: `${left}px`,
    zIndex: order,
  },
})

const StyledTooltip = styled.div.attrs(attrs)`
  background: ${({ bgColor }) => bgColor};
  color: ${({ color }) => color};
  font-family: "Montserrat";
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  animation: ${({ isOpen }) => (isOpen ? show : hide)};
  animation-duration: ${speed};
  animation-timing-function: linear;
  padding: ${4 * 2.25}px ${4 * 3}px;
  font-size: ${({ fontSize }) => fontSize};
  font-weight: 500;
  line-height: 1.87;
  letter-spacing: 0.45px;
  transition: visibility ${speed} linear;
  position: fixed;
  line-height: 1.87;
  letter-spacing: 0.45px;
  border-radius: 10px;
  max-width: 360px;
  z-index: 120;

  :after {
    content: "";
    position: absolute;
    clear: both;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    ${({ bgColor, position = "bottom" }) => arrows[position](bgColor)};
  }
`

export default StyledTooltip
