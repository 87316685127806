import React, { useEffect } from 'react';

const CalendlyWidget = () => {
  useEffect(() => {
    // Create the script element
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    // Optional: Cleanup the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div style={{ flexGrow: 1, minWidth: '320px', minHeight: '100vh', width: '100%', height: '100vh', overflow: 'hidden' }}>
    <div
      className="calendly-inline-widget"
      data-url="https://calendly.com/d/4qc-736-5f5?hide_gdpr_banner=1&text_color=001553&primary_color=5855f4"
      style={{ flexGrow: 1, display: 'flex', minWidth: '320px', minHeight: '100vh', width: '100%', height: '100vh', overflow: 'hidden' }}
      scrolling="no"
    ></div>
    </div>
  );
};

export default CalendlyWidget
