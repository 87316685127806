import React from "react"
import { Link } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

import Button from "../../../../newUikit/Button"
import Search from "../../../Search"
import ThemeSwitch from "../../../ThemeSwitch"

import DashaLogo from "../../assets/dasha-logo.inline.svg"
import DashaLogoWhite from "../../assets/dasha-logo-white.inline.svg"
import TwitterLogo from "../../assets/twitter-logo.inline.svg"
import GitHubLogo from "../../assets/github-logo.inline.svg"
import skBlackLogoSrc from "../../assets/sk-black.png"
import skWhiteLogoSrc from "../../assets/sk-white.png"
import * as S from "./styled"

const DesktopHeader = ({
  theme,
  isSearchFocused,
  content,
  setIsSearchFocused,
  openModal,
  changeTheme,
}) => {
  const { locale } = useIntl()
  const isRu = locale === "ru-ru"

  return (
    <S.Container className="inner">
      <S.LogoContainer>
        <Link to={content.logoLink}>
          {theme === "dark" ? <DashaLogoWhite /> : <DashaLogo />}
        </Link>
      </S.LogoContainer>
      <S.Nav>
        <Search theme={theme} changeIsFocused={setIsSearchFocused} />
        <S.Box searchFocused={isSearchFocused}>
          <div className="buttons-container h-card">
            {!isRu && (
              <>
                {content.links.map(item =>
                  item.link.includes("http") ? (
                    <S.Link
                      id="Documentation"
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.label}
                    </S.Link>
                  ) : (
                    <S.InnerLink to={item.link}>{item.label}</S.InnerLink>
                  )
                )}

                <div className="soc-container">
                  <S.SocLink
                    className="u-url"
                    id="Twitter"
                    href={content.twitterLink}
                    target="_blank"
                    rel="noopener noreferrer me"
                  >
                    <TwitterLogo />
                  </S.SocLink>
                  <S.SocLink
                    className="u-url"
                    id="Github"
                    href={content.gitHubLink}
                    target="_blank"
                    rel="noopener noreferrer me"
                  >
                    <GitHubLogo />
                  </S.SocLink>
                </div>
              </>
            )}
            <Button
              className="btn"
              size="sm"
              url={content.signUpLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {content.signUp}
            </Button>
            <Button
              className="btn"
              id="ContactSales"
              size="sm"
              inverse
              onClick={openModal}
            >
              {content.contactSales}
            </Button>
          </div>
          {isRu && (
            <S.SkolkovoLogo>
              <a href="https://sk.ru/" target="_blank" rel="noreferrer">
                <img className="sk-black" src={skBlackLogoSrc} alt="Skolkovo" />
                <img className="sk-white" src={skWhiteLogoSrc} alt="Skolkovo" />
              </a>
            </S.SkolkovoLogo>
          )}
          {!isRu && changeTheme && (
            <ThemeSwitch currentTheme={theme} onChange={changeTheme} />
          )}
        </S.Box>
      </S.Nav>
    </S.Container>
  )
}

export default DesktopHeader
