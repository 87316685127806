import { css, keyframes } from "styled-components"

export const arrows = {
  bottom: color => css`
    left: 50%;
    top: -16px;
    margin-left: -8x;
    border-bottom: 8px solid ${color};
  `,
  top: color => css`
    left: 50%;
    bottom: -16px;
    margin-left: -8px;
    border-top: 8px solid ${color};
  `,
  left: color => css`
    top: 50%;
    right: -16px;
    margin-top: -8px;
    border-left: 8px solid ${color};
  `,
  right: color => css`
    top: 50%;
    left: -16px;
    margin-top: -8px;
    border-right: 8px solid ${color};
  `,
}

export const show = keyframes`
    from {
        opacity: 0;
        transform: translateY(10%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`

export const hide = keyframes`
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(10%);
    }
`
